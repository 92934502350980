import React from 'react';

import './logos.css';

function SolanaLogo(props) {
  return (
    <div className='logo-wrap solana-logo-wrap'>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 96 86'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M95.5053 67.8049L79.658 84.8288C79.3134 85.1986 78.8966 85.4934 78.4334 85.6949C77.9703 85.8964 77.4706 86.0003 76.9656 86H1.8398C1.48134 86 1.13068 85.8951 0.830924 85.6982C0.531164 85.5013 0.295357 85.221 0.152475 84.8917C0.00959266 84.5624 -0.03414 84.1985 0.0266501 83.8446C0.0874403 83.4908 0.250105 83.1624 0.494658 82.8999L16.3543 65.876C16.6979 65.5072 17.1134 65.2129 17.5751 65.0115C18.0368 64.81 18.5349 64.7056 19.0385 64.7048H94.1602C94.5187 64.7048 94.8693 64.8097 95.1691 65.0066C95.4688 65.2035 95.7046 65.4838 95.8475 65.8131C95.9904 66.1424 96.0341 66.5063 95.9733 66.8601C95.9126 67.214 95.7499 67.5423 95.5053 67.8049V67.8049ZM79.658 33.5236C79.3134 33.1538 78.8966 32.859 78.4334 32.6575C77.9703 32.456 77.4706 32.3521 76.9656 32.3524H1.8398C1.48134 32.3524 1.13068 32.4573 0.830924 32.6542C0.531164 32.8511 0.295357 33.1314 0.152475 33.4607C0.00959266 33.79 -0.03414 34.1539 0.0266501 34.5078C0.0874403 34.8616 0.250105 35.19 0.494658 35.4525L16.3543 52.4764C16.6979 52.8452 17.1134 53.1394 17.5751 53.3409C18.0368 53.5424 18.5349 53.6468 19.0385 53.6476H94.1602C94.5187 53.6476 94.8693 53.5427 95.1691 53.3458C95.4688 53.1489 95.7046 52.8686 95.8475 52.5393C95.9904 52.21 96.0341 51.8461 95.9733 51.4922C95.9126 51.1384 95.7499 50.81 95.5053 50.5475L79.658 33.5236ZM1.8398 21.2952H76.9656C77.4706 21.2955 77.9703 21.1917 78.4334 20.9902C78.8966 20.7887 79.3134 20.4938 79.658 20.124L95.5053 3.1001C95.7499 2.83758 95.9126 2.50922 95.9733 2.15538C96.0341 1.80153 95.9904 1.4376 95.8475 1.10831C95.7046 0.779013 95.4688 0.498699 95.1691 0.301804C94.8693 0.10491 94.5187 1.21255e-05 94.1602 0L19.0385 0C18.5349 0.000858433 18.0368 0.105251 17.5751 0.306715C17.1134 0.50818 16.6979 0.802426 16.3543 1.17124L0.498747 18.1951C0.25443 18.4574 0.0918367 18.7854 0.0309086 19.1389C-0.0300194 19.4923 0.0133662 19.8559 0.155745 20.1851C0.298123 20.5142 0.533305 20.7945 0.832447 20.9918C1.13159 21.189 1.48169 21.2944 1.8398 21.2952V21.2952Z'
          fill='url(#paint0_linear_55_222)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_55_222'
            x1='8.10352'
            y1='88.0497'
            x2='87.0299'
            y2='-0.77775'
            gradientUnits='userSpaceOnUse'
          >
            <stop offset='0.08' stopColor='#9945FF' />
            <stop offset='0.3' stopColor='#8752F3' />
            <stop offset='0.5' stopColor='#5497D5' />
            <stop offset='0.6' stopColor='#43B4CA' />
            <stop offset='0.72' stopColor='#28E0B9' />
            <stop offset='0.97' stopColor='#19FB9B' />
          </linearGradient>
        </defs>
      </svg>
      <div className='solana-logo-spacer'></div>
      <svg
        className='solana-logo-text'
        width='120%'
        height='100%'
        viewBox='0 0 500 76'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_4_2298)'>
          <path d='M71.6899 15.57V0.459961H14.3799C10.4016 0.459961 6.58633 2.04031 3.77328 4.85336C0.960235 7.6664 -0.620117 11.4817 -0.620117 15.46V30.79C-0.620117 34.7682 0.960235 38.5835 3.77328 41.3966C6.58633 44.2096 10.4016 45.79 14.3799 45.79H59.8099V60.89H0.459883V76H59.9199C63.8981 76 67.7134 74.4196 70.5265 71.6066C73.3395 68.7935 74.9199 64.9782 74.9199 61V45.68C74.9199 41.7017 73.3395 37.8864 70.5265 35.0734C67.7134 32.2603 63.8981 30.68 59.9199 30.68H14.4899V15.57H71.6899Z' />
          <path d='M87.2998 15.46V61C87.2998 64.9782 88.8802 68.7935 91.6932 71.6066C94.5062 74.4196 98.3216 76 102.3 76H147.84C151.818 76 155.633 74.4196 158.446 71.6066C161.259 68.7935 162.84 64.9782 162.84 61V15.46C162.84 11.4817 161.259 7.6664 158.446 4.85336C155.633 2.04031 151.818 0.459961 147.84 0.459961H102.3C98.3216 0.459961 94.5062 2.04031 91.6932 4.85336C88.8802 7.6664 87.2998 11.4817 87.2998 15.46ZM147.73 60.89H102.38V15.57H147.7L147.73 60.89Z' />
          <path d='M248 15.46V76H263.11V51.18H307.37V76H322.47V15.46C322.47 11.4817 320.89 7.6664 318.077 4.85336C315.264 2.04031 311.448 0.459961 307.47 0.459961H263C259.022 0.459961 255.206 2.04031 252.393 4.85336C249.58 7.6664 248 11.4817 248 15.46ZM307.37 36.07H263.12V15.57H307.38L307.37 36.07Z' />
          <path d='M424.92 15.46V76H440V51.18H484.25V76H499.36V15.46C499.36 11.4817 497.78 7.6664 494.967 4.85336C492.153 2.04031 488.338 0.459961 484.36 0.459961H439.92C435.942 0.459961 432.126 2.04031 429.313 4.85336C426.5 7.6664 424.92 11.4817 424.92 15.46ZM484.27 36.07H440V15.57H484.25L484.27 36.07Z' />
          <path d='M396.38 0.459961V60.89H390.38L368.66 6.74996C367.919 4.89434 366.638 3.30338 364.984 2.18224C363.33 1.06111 361.378 0.461226 359.38 0.459961H345.93C343.278 0.459961 340.734 1.51353 338.859 3.38889C336.983 5.26426 335.93 7.8078 335.93 10.46V76H351V15.57H357.05L378.71 69.72C379.453 71.5753 380.736 73.1652 382.392 74.2846C384.048 75.404 386.001 76.0015 388 76H401.45C404.102 76 406.646 74.9464 408.521 73.071C410.396 71.1957 411.45 68.6521 411.45 66V0.459961H396.38Z' />
          <path d='M191.38 60.89V0.459961H176.29V61C176.29 64.9782 177.87 68.7935 180.683 71.6066C183.496 74.4196 187.312 76 191.29 76H236.72V60.89H191.38Z' />
        </g>
        <defs>
          <clipPath id='clip0_4_2298'>
            <rect width='500' height='76' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

SolanaLogo.displayName = 'SolanaLogo';

export default SolanaLogo;
