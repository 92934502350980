import React from 'react';

function NearLogo(props) {
  return (
    <div className='logo-wrap'>
      <svg
        id='Layer_1'
        data-name='Layer 1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 108 153'
        width='100%'
        height='100%'
        {...props}
      >
        <path d='M22.6,126.38v26.24a.38.38,0,0,1-.37.38H19.47a3.73,3.73,0,0,1-3.14-1.71L3.93,132.13l.43,9.57v10.92A.38.38,0,0,1,4,153H.38a.38.38,0,0,1-.38-.38V126.38A.38.38,0,0,1,.38,126H3.09a3.75,3.75,0,0,1,3.15,1.71l12.39,19.12-.39-9.53V126.38a.38.38,0,0,1,.38-.38h3.61A.38.38,0,0,1,22.6,126.38Z' />
        <path d='M59.51,153H55.68a.37.37,0,0,1-.34-.51l10.11-26.13A.56.56,0,0,1,66,126h4.78a.55.55,0,0,1,.52.36L81.4,152.49a.37.37,0,0,1-.35.51H77.23a.37.37,0,0,1-.35-.24l-8.16-21.52a.38.38,0,0,0-.7,0l-8.16,21.52A.39.39,0,0,1,59.51,153Z' />
        <path d='M107.92,152.39l-7.59-9.69c4.28-.81,6.79-3.7,6.79-8.14,0-5.09-3.32-8.56-9.18-8.56H87.36a.57.57,0,0,0-.57.56h0a3.61,3.61,0,0,0,3.61,3.61h7.08c3.55,0,5.24,1.81,5.24,4.43s-1.65,4.48-5.24,4.48H87.36a.57.57,0,0,0-.57.56v13a.38.38,0,0,0,.38.38h3.61a.38.38,0,0,0,.37-.38v-9.69h4.17l6.59,8.6a3.74,3.74,0,0,0,3,1.47h2.74A.38.38,0,0,0,107.92,152.39Z' />
        <path d='M48.09,126H31.33a.5.5,0,0,0-.5.5h0a3.67,3.67,0,0,0,3.67,3.67H48.09a.38.38,0,0,0,.37-.38v-3.41A.38.38,0,0,0,48.09,126Zm0,22.83H35.57a.37.37,0,0,1-.38-.37v-6.77a.38.38,0,0,1,.38-.38H47.12a.37.37,0,0,0,.38-.37v-3.42a.37.37,0,0,0-.38-.37H31.4a.57.57,0,0,0-.57.56v14.73a.57.57,0,0,0,.57.56H48.09a.38.38,0,0,0,.37-.38v-3.41A.38.38,0,0,0,48.09,148.83Z' />
        <path d='M86.69,5.49,64.11,39a2.4,2.4,0,0,0,3.56,3.15L89.9,22.89a.9.9,0,0,1,1.48.68V83.92a.9.9,0,0,1-1.59.57L22.64,4.07A11.53,11.53,0,0,0,13.85,0H11.51A11.51,11.51,0,0,0,0,11.51v85A11.51,11.51,0,0,0,11.51,108h0a11.49,11.49,0,0,0,9.8-5.49L43.89,69a2.4,2.4,0,0,0-3.56-3.15L18.1,85.11a.9.9,0,0,1-1.48-.68V24.08a.9.9,0,0,1,1.59-.57l67.15,80.42A11.53,11.53,0,0,0,94.15,108h2.34A11.51,11.51,0,0,0,108,96.49v-85A11.51,11.51,0,0,0,96.49,0h0A11.49,11.49,0,0,0,86.69,5.49Z' />
      </svg>
    </div>
  );
}

NearLogo.displayName = 'NearLogo';

export default NearLogo;
